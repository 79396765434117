import React from 'react'

export const Solutions = () => {
  return (
    <div>
        <div class="bg-gradient-to-l from-sky-950 via-sky-950 to-sky-950 py-20  mt-36">
                <div class="max-w-screen-lg mx-auto flex justify-between items-center">
                    <div class="max-w-xl">
                        <h2 class="text-gray-100 text-3xl mb-4">As the experts in this field, we have ready made solutions for</h2>
                        <p class="text-base text-gray-100">Chat Bots, Natural Language DB Query, Profanity and Professional Language checkers, sentiment analysis, Anamoly Detection, Forecasting and Fraud Detection.</p>
                    </div>
                    <button class="text-gray-100 uppercase py-3 text-base px-10 border border-sky-950 hover:bg-sky-950 hover:bg-opacity-10">Get started</button>
                </div>
            </div>
    </div>
  )
}
