import React from 'react'
import heroImage from '../images/help3.webp';  // Adjust the path as necessary

export const Section0 = () => {
    return (
        <div>
            <div class="py-16 relative overflow-hidden bg-white">
                <div class="grid grid-cols-2 max-w-screen-lg mx-auto">
                    <div class="w-full flex flex-col items-end pr-16">
                        <div class="h-full mt-auto overflow-hidden relative">
                            <img src={heroImage} alt="Consulting" />
                        </div>
                    </div>

                    <div>
                        <div class="relative z-20 pl-12">
                            <h2 class="text-sky-950 text-5xl mb-10">Autodatamation is here to help you</h2>
                            <p class="text-sky-950 text-lg">
                                we integrate AI into your business processes, and help you build AI products that are tailored to your needs.
                            </p>
                            <button class="mt-8 text-white uppercase py-3 text-sm px-10 border border-white hover:bg-white hover:bg-opacity-10">Talk with expert</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
