import { Card } from "./components/Card";
import { Footer } from "./components/Footer";
import Header from "./components/Header";
import { Hero } from "./components/Hero";
import { Products } from "./components/Products";
import { Section0 } from "./components/Section0";
import { Solutions } from "./components/Solutions";
import { Home } from "./Home";

function App() {
  return (
    <div>
      <Header />
      <Hero />
      <Products />
      <Solutions />
      <Section0 />

      <Footer />

      {/* <Home /> */}

    </div>
  );
}

export default App;
