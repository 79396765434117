import React from 'react';

// Sample images, replace these with your actual images
import productImage1 from '../images/chatbot2.webp';
import productImage2 from '../images/sql-assistant2.webp';
import productImage3 from '../images/obscenity-checker3.webp';

export const Products = () => {
    return (
        <div className="bg-white" style={{ height: '100vh' }}>
            <div className="container mx-auto px-6 py-20">
                <h1 className="text-5xl text-center text-gray-800 mb-20">PRODUCTS</h1>
                <div className="flex items-stretch space-x-4">
                    {/* Card 1 */}
                    <div className="w-1/3 bg-white shadow-lg rounded-lg overflow-hidden">
                        <img className="w-full h-54 object-cover" src={productImage1} alt="Product 1" />
                        <div className="p-4">
                            <h2 className="text-gray-800 text-lg font-bold">Support Chat Bot</h2>
                            <p className="text-gray-800">Create your own support chat bot with a few clicks</p>
                            <button className="mt-4 bg-sky-800 hover:bg-sky-700 text-white font-semi-bold py-2 px-4 rounded">View More</button>
                        </div>
                    </div>

                    {/* Card 2 */}
                    <div className="w-1/3 bg-white shadow-lg rounded-lg overflow-hidden">
                        <img className="w-full h-54 object-cover" src={productImage2} alt="Product 2" />
                        <div className="p-4">
                            <h2 className="text-gray-800 text-lg font-bold">SQL DB Assistant</h2>
                            <p className="text-gray-800">Chat with DB assistant to extract data and reports from your SQL DB</p>
                            <button className="mt-4 bg-sky-800 hover:bg-sky-700 text-white font-semi-bold py-2 px-4 rounded">View More</button>
                        </div>
                    </div>

                    {/* Card 3 */}
                    <div className="w-1/3 bg-white shadow-lg rounded-lg overflow-hidden">
                        <img className="w-full h-54 object-cover" src={productImage3} alt="Product 3" />
                        <div className="p-4">
                            <h2 className="text-gray-800 text-lg font-bold">Obscenity Checker</h2>
                            <p className="text-gray-800">Automatically proof-read user comments and text for profanity and Obscenity before publishing</p>
                            <button className="mt-4 bg-sky-800 hover:bg-sky-700 text-white font-semi-bold py-2 px-4 rounded">View More</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
