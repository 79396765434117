import React from 'react'

export const Card = () => {
    return (
        <div class="max-w-sm rounded overflow-hidden shadow-lg">
            {/* <img class="w-full" src="https://via.placeholder.com/400x200" alt="Card image cap"> */}
            <div class="px-6 py-4">
                <div class="font-bold text-xl mb-2">Card Title</div>
                <p class="text-gray-700 text-base">
                    This is a simple card example using Tailwind CSS. You can put any content here such as text, images, and links.
                </p>
            </div>
            <div class="px-6 pt-4 pb-2">
                <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    Button
                </button>
            </div>
        </div>
    )
}
