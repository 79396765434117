import React from 'react';
import heroImage from '../images/hero2.webp';  // Adjust the path as necessary

export const Hero = () => {
    return (
        <div className="mx-auto flex flex-wrap items-center justify-between">
            <div className="flex-1 p-4 bg-gradient-to-l from-[#d6d8d8] via-white to-white" style={{height: '100vh'}}>
                <h1 className="text-5xl text-gray-600 text-center" style={{marginTop: 240}}>
                    Enablers, Integrators, and <span className='text-sky-700'>Consultants</span>
                </h1>
                <br/>
                <h2 className="text-2xl text-gray-600 text-center">
                    We offer specialized services in AI complemented by <div className='text-sky-500'>our innovative products</div>
                </h2>
            </div>
            <div className="flex-1 bg-gradient-to-l from-[#d6d8d8] via-white to-white">
                <img src={heroImage} alt="Consulting"/>
            </div>
        </div>
    );
}
