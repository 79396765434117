import React from 'react';
import NavLink from './Navlink';
import Dropdown from './Dropdown';

function Header() {
  const [isOpen, setIsOpen] = React.useState(false);

  const products = [
    { label: 'Support Chat Bot', href: '#support-chat-bot' },
    { label: 'SQL DB Assistant', href: '#sql-db-assistant' },
    { label: 'Obscenity Checker', href: '#obscenity checker' },
  ];

  const solutions = [
    { label: 'Chat Bots', href: '#solutions' },
    { label: 'Natural Language DB Query', href: '#solutions' },
    { label: 'Profanity And Professional Language Checkers', href: '#solutions' },
    { label: 'Sentiment Analysis', href: '#solutions' },
    { label: 'Anamoly detection', href: '#solutions' },
    { label: 'Fraud Detection', href: '#solutions' },
    { label: 'Forecasting', href: '#solutions' },
  ];
  return (
    <header className="bg-gradient-to-l from-gray-700 via-gray-300 to-gray-800 shadow-md">
      <div className="container mx-auto px-6 py-3 flex justify-between items-center">
        <a href="/" className="text-xl font-semibold text-gray-200">
          autodatamation
        </a>
        <nav className="flex space-x-4">
          <Dropdown label="SOLUTIONS" items={solutions} />
          <Dropdown label="PRODUCTS" items={products} />
          <NavLink href="/products">ABOUT US</NavLink>
          <a href="/sign-in" className="text-yellow-200 hover:text-blue-700 transition-colors duration-300 font-medium">
            SIGN IN
          </a>
        </nav>
      </div>
    </header>
  );
}

export default Header;
