import {useState, useEffect, useRef} from 'react';
import {ChevronDown} from 'lucide-react';

const Dropdown = ({ label, items, isMobile = false }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
  
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setIsOpen(false);
        }
      };
  
      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);
  
    if (isMobile) {
      return (
        <div className="space-y-1">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="w-full flex items-center justify-between text-gray-200 hover:text-sky-300 px-3 py-2 rounded-md text-base font-medium"
          >
            {label}
            <ChevronDown className={`w-4 h-4 transition-transform ${isOpen ? 'rotate-180' : ''}`} />
          </button>
          {isOpen && (
            <div className="space-y-1">
              {items.map((item) => (
                <a
                  key={item.label}
                  href={item.href}
                  className="block text-gray-200 hover:text-sky-300 px-3 py-2 rounded-md text-sm font-medium"
                >
                  {item.label}
                </a>
              ))}
            </div>
          )}
        </div>
      );
    }
  
    return (
      <div className="relative" ref={dropdownRef}>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="flex items-center px-2 text-gray-200 hover:text-sky-300 font-medium"
        >
          {label}
          <ChevronDown className={`ml-1 w-4 h-4 transition-transform ${isOpen ? 'rotate-180' : ''}`} />
        </button>
        {isOpen && (
          <div className="absolute top-full left-0 mt-1 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
            <div className="py-1" role="menu">
              {items.map((item) => (
                <a
                  key={item.label}
                  href={item.href}
                  className="block px-4 py-2 text-sm text-gray-500 hover:bg-gray-400 hover:text-gray-100"
                  role="menuitem"
                >
                  {item.label}
                </a>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }
  
  export default Dropdown;